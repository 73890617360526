import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontends/main/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageWrapper","PageWrapperInner"] */ "/app/frontends/main/src/app/styled.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontends/main/src/page-components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontends/main/src/page-components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontends/ol-components/src/index.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
